import React from "react"
import ReactWOW from "react-wow"
import { Link, graphql } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"
import MoneyAU from "../assets/images/personal-loan/money-au-logo.svg"
import BgGraphic from "../assets/images/personal-loan/personal-loan-graphic.svg"

const PersonalLoans = () => {
  return (
    <Layout>
      <SEO
        title="Personal Loans | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
     
      <div className="personal-loans-hero-banner">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-9">
            <div className="main-banner-content">
                <p class="h1-accent">The Best Personal Loan.</p>
              <h1>
              Save <span className="">$1000s</span> in Interest and Fees.
              </h1>
              <h2 className="mt-3 mt-lg-5">Find yours from 11 lenders in 60 seconds!</h2>
              <p className="subtitle">100% online</p>
              <ReactWOW delay=".05s" animation="fadeInUp" >
                <div className="btn-box pt-2 pt-lg-3">
                  <Link
                    to="https://www.money.com.au/r/Oxygen-Home-Loans"
                    className="default-btn primary-btn btn-lg mb-2 mr-0 mx-2"
                  >
                    Apply Now <span></span>
                  </Link>
                </div>
              </ReactWOW>

              <div className="wr-ownership">
                <div className="row mx-auto">
                  <div className="car-clarity col">
                    <div>
                      <label className="mb-2">Powered by</label>
                      <img  src={MoneyAU} alt="money-AU" />
                    </div>
                  </div>
                  
                </div>
              </div>
            
            </div>
            
          </div>

        </div>
      </div>
            
              <ReactWOW delay=".05s" animation="fadeIn">
              <div className="wr-car-loan">
                <img src={BgGraphic} alt="personal-loan-graphic" />      
              </div>   
              </ReactWOW>           
            
      </div>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default PersonalLoans
